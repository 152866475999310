<template>
  <div>
    <div class="pc container">
      <div class="h5 main" style="padding-top:60px;padding-bottom:28px">소스 파일 다운로드</div>
      <div class="box-card">
        <div v-for="(card, idx) in list" :key="`card-${idx}`"
          class="card">
          <div class="flex-between">
            <div class="flex-align unselect" style="gap:12px">
              <img class="img-product" :src="card.order_product.product.img" alt=""
                   @click="routerPush(`/theme_detail?id=${card.order_product.product.id}`)">
              <div style="flex:1">
                <div class="body6 sub3">{{ card.order_product.product.partner.name }}</div>
                <div class="body3-medium main"
                     @click="routerPush(`/theme_detail?id=${card.order_product.product.id}`)">{{ card.order_product.product.name }}</div>
                <div v-if="card.order_product.option"
                  class="body5 sub3">{{ card.order_product.option.name }}</div>
                <div class="flex-align" style="gap:8px">
                  <div class="body5-bold sub">{{ card.total_price|currencyNum }}<span class="body4">원</span></div>
                  <div class="size-14 primary text-underline unselect" @click="clickDetail(card)">구매내역</div>
                </div>
              </div>
            </div>

            <div class="flex-align" style="gap:12px">
              <div class="exts">
                <img v-for="(ext,idx) in card.order_product.product.exts.slice(0,5)" :key="`ext-${idx}`"
                     :src="`/static/icon/ext_${ext.toLowerCase()}.svg`">
              </div>
              <div style="width:140px">
                <button v-if="!card.order_product.review"
                        class="button is-primary-light" @click="clickReviewReg(card)"
                        style="width:140px;height:48px">후기작성</button>
              </div>
              <button class="button is-primary" style="width:140px;height:48px" @click="clickDownload(card)">다운로드</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="mobile container">
      <div class="h7 main" style="padding:24px 0 16px 0">소스 파일 다운로드</div>
      <div class="box-card">
        <div v-for="(card, idx) in list" :key="`card-${idx}`"
             class="card">
          <div class="flex-between" style="gap:16px">
            <div>
              <div class="body6 sub3">{{ card.order_product.product.partner.name }}</div>
              <div class="body3-medium main"
                   @click="routerPush(`/theme_detail?id=${card.order_product.product.id}`)">
                {{ card.order_product.product.name }}</div>
              <div v-if="card.order_product.option"
                   class="body5 sub3">{{ card.order_product.option.name }}</div>
              <div class="flex-align" style="gap:8px">
                <div class="body5-bold sub">{{ card.total_price|currencyNum }}<span class="body4">원</span></div>
                <div class="size-14 primary text-underline unselect" @click="clickDetail(card)">구매내역</div>
              </div>
              <div class="exts">
                <img v-for="(ext,idx) in card.order_product.product.exts.slice(0,5)" :key="`ext-${idx}`"
                     :src="`/static/icon/ext_${ext.toLowerCase()}.svg`">
              </div>
            </div>
            <img class="img-product" :src="card.order_product.product.img" alt=""
                 @click="routerPush(`/theme_detail?id=${card.order_product.product.id}`)">
          </div>
          <div class="flex-align" style="margin-top:20px;gap:8px">
            <button v-if="!card.order_product.review"
                    class="button is-primary-light body4" @click="clickReviewReg(card)"
                    style="flex:1;height:40px">후기작성</button>
            <button class="button is-primary body4" style="flex:1;height:40px" @click="clickDownload(card)">다운로드</button>
          </div>
        </div>
      </div>
    </div>

    <review-reg-modal v-if="selectedProduct" ref="reviewModal" :isAdmin="false" :order_id="selectedProduct.id"
                      :product="selectedProduct.order_product.product" @done="onReviewDone"></review-reg-modal>

    <order-detail-modal v-if="selectedProduct" ref="orderDetailModal"
                        :order="selectedProduct"></order-detail-modal>
  </div>
</template>

<script>
  import ReviewRegModal from "../modal/ReviewRegModal";
  import UserAPIMixin from "../../mixins/UserAPIMixin";
  import OrderDetailModal from "../modal/OderDetailModal";
  export default {
    name: "MyTemplates",
    components: {OrderDetailModal, ReviewRegModal},
    mixins: [
      UserAPIMixin
    ],
    created() {
      this.getData();
    },
    data() {
      return {
        list: [],
        selectedProduct: undefined
      }
    },
    methods: {
      getData() {
        this.request.user.get('launchpack/v1/templates/order').then(res => {
          this.list = res.data;
        });
      },
      clickDetail(card) {
        this.selectedProduct = card;
        setTimeout(()=>{
          this.$refs.orderDetailModal.open();
        }, 100);
      },
      clickDownload(card) {
        let files = card.order_product.product.files;
        if(card.order_product.option) {
          files = card.order_product.option.files;
        }
        const a = document.createElement("a");
        a.href = files[0];
        a.style.display = 'none';
        a.setAttribute('download', files[0]);
        document.body.appendChild(a);
        a.click();
        a.parentNode.removeChild(a);

      },
      clickReviewReg(product) {
        this.selectedProduct = product;
        setTimeout(()=>{
          this.$refs.reviewModal.open();
        }, 100);
      },
      onReviewDone() {
        this.selectedProduct.order_product.review = true;
      }
    }
  }
</script>

<style lang="stylus" scoped>
  @import '~assets/css/lp_main'
  .pc
    .box-card
      background-color white
      margin-top 24px
      padding 0 32px
      border-radius 12px

    .card
      padding 44px 0
      border-bottom 1px solid $gray1

    .card:last-child
      border-bottom 0

    .img-product
      width 100px
      height 75px
      border-radius 6px
      object-fit cover
      object-position center

    .exts
      display flex
      align-items center
      gap 8px
      margin-right 36px
      img
        height 24px
  .mobile
    .box-card
      background-color white
      border-radius 12px

    .card
      padding 16px
      border-bottom 1px solid $gray1

    .card:last-child
      border-bottom 0

    .img-product
      width 100px
      height 75px
      border-radius 6px
      object-fit cover
      object-position center

    .exts
      margin-top 8px
      display flex
      align-items center
      gap 6px
</style>
